import React from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const success = () => {
  return (
    <>
      <Seo title="Message Sent" />
      <section class="success">
        <div className="success__animation">
          <Player
            autoplay={true}
            controls={false}
            loop={true}
            src="https://assets2.lottiefiles.com/packages/lf20_y9qOnk.json"
            style={{ width: "100%" }}
            background="transparent"
            speed="1"
          ></Player>
        </div>
        <div className="success__text">
          Yay, your message has been sent.
          <br />
          I'll get in touch shortly!
        </div>
        <div className="success__cta">
          <Link to="/">Back To Home</Link>
        </div>
      </section>
    </>
  )
}

export default success
